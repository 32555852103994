.ant-card-body {
  padding: 24px !important;
}

.ant-modal-header {
  border-bottom: 1px solid #e8e8e8 !important;
  margin: -4px -24px 24px !important;
  padding: 0 20px 16px !important;
}

.ant-modal-footer {
  border-top: 1px solid #e8e8e8 !important;
  margin: 24px -24px -10px !important;
  padding: 10px 20px 0 !important;
}

.ant-notification-notice {
  padding-right: 70px !important;
}

@font-face {
  font-family: 'Procivis-Book';
  src:
    local('Procivis-Book'),
    url(./../assets/fonts/Procivis-Book.otf) format('opentype');
}

.ant-page-header-heading-title {
  font-family: 'Procivis-Book' !important;
  font-weight: 500 !important;
  line-height: 28px !important;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #ffffff !important;
}
