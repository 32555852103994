.responseErrorNotification :global(.ant-notification-notice-icon) {
  left: 34px !important;
}

.responseErrorNotification :global(.ant-notification-notice-message) {
  margin-left: 44px !important;
}

.responseErrorNotification :global(.ant-notification-notice-description) {
  margin-left: 0 !important;
}
