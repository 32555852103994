.cardWrapper {
  width: 600px;
  max-width: 600px;
  margin: auto;
}

.container {
  display: flex;
  margin-top: 48px;
  justify-content: center;
  align-content: center;
  align-items: center;
}
