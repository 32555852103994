.responseErrorNotificationDetails :global(.ant-collapse-content) {
  margin-right: -46px !important;
}

.responseErrorNotificationDetails :global(.ant-collapse-content-box) {
  padding: 0 !important;
}

.responseErrorNotificationDetailsWrapper {
  position: relative;
}

.responseErrorNotificationDetailsText {
  text-wrap: nowrap;
  overflow-x: scroll;
}

.responseErrorNotificationDetailsText:focus {
  outline: none;
}

.responseErrorNotificationDetailsCopyButton {
  position: absolute;
  right: 16px;
  bottom: 16px;
}
