.one-table table {
  table-layout: fixed !important;
}

.one-table-column-actions {
  width: 135px;
  white-space: nowrap;

  > .ant-btn {
    margin-left: -15px;

    &:last-child {
      margin-right: -15px;
    }
  }

  > a {
    margin-left: -15px;

    &:last-child {
      margin-right: -15px;
    }
  }
}

.one-table-column-code {
  > .ant-typography {
    display: flex;

    > code {
      display: inline-block;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  > a {
    > span {
      max-width: 100%;
      word-wrap: break-word;
      white-space: normal;
    }
  }

  > span {
    max-width: 100%;
    word-wrap: break-word;
    white-space: normal;
  }
}

.one-date-picker {
  width: 100%;
}

td.ant-descriptions-item-content a {
  text-decoration: underline;
}
